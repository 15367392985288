<template>
  <section class="case-creation__step case-creation__patient-information">
    <div class="case-creation__card">
      <div class="case-creation__heading edit-view">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-patient-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Patient Information</h3>
        </div>
      </div>

      <div class="case-creation__body pb-0">
        <div class="vue-data" v-if="getPatientInformation">
          <el-row :gutter="20">
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">First Name</h4>
                <p class="value" v-if="getPatientInformation.first_name">
                  {{ getPatientInformation.first_name }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Middle Name</h4>
                <p class="value" v-if="getPatientInformation.middle_name">
                  {{ getPatientInformation.middle_name }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Last Name</h4>
                <p class="value" v-if="getPatientInformation.last_name">
                  {{ getPatientInformation.last_name }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Gender</h4>
                <p class="value" v-if="getPatientInformation.gender">
                  {{ getPatientInformation.gender }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Date of Birth</h4>
                <p class="value" v-if="getPatientInformation.date_of_birth">
                  {{ formatDate(getPatientInformation.date_of_birth) }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">EMR</h4>
                <p class="value" v-if="getPatientInformation.emr">
                  {{ getPatientInformation.emr }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Mobile Number</h4>
                <p class="value" v-if="getPatientInformation.home_phone">
                  {{ getPatientInformation.home_phone }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Phone Number</h4>
                <p class="value" v-if="getPatientInformation.work_phone">
                  {{ getPatientInformation.work_phone }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Email</h4>
                <p class="value" v-if="getPatientInformation.email">
                  {{ getPatientInformation.email }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Address Line 1</h4>
                <p class="value" v-if="getPatientInformation.address_line_1">
                  {{ getPatientInformation.address_line_1 }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Address Line 2</h4>
                <p class="value" v-if="getPatientInformation.address_line_2">
                  {{ getPatientInformation.address_line_2 }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">County</h4>
                <p class="value" v-if="getPatientInformation.county">
                  {{ getPatientInformation.county }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Zip</h4>
                <p class="value" v-if="getPatientInformation.zip">
                  {{ getPatientInformation.zip }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">State</h4>
                <p class="value" v-if="getPatientInformation.state">
                  {{ getPatientInformation.state }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">City</h4>
                <p class="value" v-if="getPatientInformation.city">
                  {{ getPatientInformation.city }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Race</h4>
                <p class="value" v-if="getPatientInformation.race">
                  {{ getPatientInformation.race }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Ethnicity</h4>
                <p class="value" v-if="getPatientInformation.ethnicity">
                  {{ getPatientInformation.ethnicity }}
                </p>
                <p class="value" v-else>--</p>
              </div>
            </el-col>
            <el-col
              :xl="3"
              :lg="4"
              :md="8"
              :sm="12"
              :xs="12"
              v-if="getPatientInformation.pregnant"
            >
              <div class="input-value view-cases">
                <h4 class="name">Pregnancy</h4>
                <p class="value">
                  {{ getPatientInformation.pregnant ? "Yes" : "No" }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";

export default {
  mixins: [UserPermissionsHelper],

  computed: {
    ...mapGetters("cases", ["getCaseInformation"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    getPatientInformation() {
      if (
        this.getCaseInformation &&
        Object.keys(this.getCaseInformation).length > 0 &&
        this.getCaseInformation.patient_info
      ) {
        return this.getCaseInformation.patient_info;
      }
      return null;
    },
  },
  mounted() {},
  methods: {
    goToEditForm() {
      this.$router.push({
        name: "Case-Information-Update",
        params: { case_id: this.$route.params.case_id },
        query: this.$route.query,
      });
    },
  },
};
</script>
